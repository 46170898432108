import React from "react"
import { Container, List, Header, Icon, Breadcrumb, Menu, Message } from "semantic-ui-react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/progmiscon-logo-64.png"
import SelfCheckButton from "../components/SelfCheckButton"
import ConceptLabel from "../components/ConceptLabel"
const _ = require("lodash")

function getFirstSentences(str, n) {
  let re = /\b(\w\.\w\.)|([.?!])\s+(?=[A-Za-z])/g; 
  let result = str.replace(re, function(m, g1, g2){
    return g1 ? g1 : g2+"\r";
  });
  return result.split("\r", n).join(' ');
}

const ConceptPageTemplate = (args) => {
  const { pageContext, data } = args;
  const { concept, wikipediaSummary, pl } = pageContext;
  const conceptName = _.startCase(concept);
  const { edges, totalCount } = data.misconceptions;
  const edgesToPresent = edges.filter(({node}) => pl===undefined||node.fields.pl===pl);
  // all known PLs ["Java", "JavaScript", ...]
  const pls = data.pls.edges.map(({node}) => node.frontmatter.id);
  const wikipediaId = data.concept.frontmatter.wikipedia;
  const shortDescription = data.concept.frontmatter.shortDescription;
  const relatedConcepts = data.concept.frontmatter.relatedConcepts;
  const wikipediaLink = `https://en.wikipedia.org/wiki/${wikipediaId}`;
  const texts = data.concept.frontmatter.text;
  const textbooks = !texts ? [] : texts.flatMap(t => {
    const book = data.textbooks.nodes.find(node => node.parent.name === t.id);
    return !book ? [] : [{name: book.frontmatter.title,
                           sections: t.sections,
                           address: book.frontmatter.home}];
  });
  const textBooksComponent = textbooks.length == 0 ? "" : (
    <Message icon info>
      <Icon name="info circle" />
      <Message.Content>
        <Message.Header>Authoritative Definition</Message.Header>
        <p>
          Wikipedia does not necessarily offer the best fitting definition of a concept.
          The exact meaning we attribute to the concept of <strong>{conceptName}</strong> is
          based on principled authoritative sources such as:
        </p>
        <List>
          {textbooks.map(textbook =>
            <List.Item key={textbook.name}>
              <a href={textbook.address}>
                {textbook.name}
                {!textbook.sections ? "" :
                  ` - Section${textbook.sections.length > 1 ? "s" : ""} ` +
                  textbook.sections.join(', ')}
              </a>
            </List.Item>
          )}
        </List>
      </Message.Content>
    </Message>
  )

  return (
    <Layout>
      <SEO title={conceptName} />
      <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section link as={Link} to="/concepts">
            Concepts
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>{conceptName}</Breadcrumb.Section>
        </Breadcrumb>

        <Header as="h1" dividing>
          <Icon name="lightbulb" />
          <Header.Content>
            {conceptName}
            {wikipediaId ? "" :
              <Header.Subheader>
                {totalCount} Misconception{totalCount === 1 ? "" : "s"} about {conceptName}
              </Header.Subheader>
            }
          </Header.Content>
        </Header>
        <p style={{fontSize: '120%'}}>
          {shortDescription}
        </p>
        {
          relatedConcepts
          ?
          <p>
            Related concepts:&nbsp;&nbsp;
            {
              relatedConcepts
              .map(c => (<ConceptLabel key={`concepts-${c}`} concept={c}/>))
            }
          </p>
          :
          null
        }
        {!wikipediaId ? textBooksComponent :
          <>
            <p>
              <i>Closest Wikipedia entry:</i>&nbsp;&nbsp;
              <a href={wikipediaLink}>{wikipediaId.replace(/_/g, " ").replace(/#/, ": ")}</a>&nbsp;&mdash;&nbsp;
              {getFirstSentences(wikipediaSummary, 3)}
            </p>

            {textBooksComponent}

            <Header as="h2" dividing>
              <Header.Content>
                Misconceptions about {conceptName}
              </Header.Content>
              <Header.Subheader>
                {totalCount} documented Misconception{totalCount === 1 ? "" : "s"}
              </Header.Subheader>
            </Header>
          </>
        }

        <Menu secondary>
          <Menu.Item 
            as={Link} 
            to={`/concepts/${_.kebabCase(concept)}/`}
            active={pl === undefined}
          >
            Any Language
          </Menu.Item>
          {pls.map((itemPl) =>
            <Menu.Item
              key={itemPl}
              as={Link} 
              to={`/concepts/${_.kebabCase(concept)}/${itemPl}/`}
              active={itemPl === pl}
            >
              {itemPl}
            </Menu.Item>
          )}
        </Menu>

        {edgesToPresent.length>0 ? <SelfCheckButton primary pl={pl} concepts={[concept]}/> : null}

        <List selection verticalAlign="middle">
          {edgesToPresent.map(({ node }) => (
            <List.Item as={Link} to={node.fields.slug} key={node.fields.slug}>
              <img
                className="ui image"
                alt="Misconception"
                width="20"
                src={logo}
              />
              <List.Content>
                <List.Header>
                  {/* color this like a link, without producing a nested a element */}
                  <span style={{ color: "#4183c4" }}>{node.fields.name}</span>
                  
                  { pl ? "" : 
                  <span> &mdash; {node.fields.pl}</span>}
                </List.Header>
                {node.frontmatter.shortDescription}
              </List.Content>
            </List.Item>
          ))}
          {edgesToPresent.length ? "" : 
            <List.Item>No known {pl ? pl : ""} misconceptions for this concept</List.Item>
          }
        </List>
      </Container>
    </Layout>
  )
}

export default ConceptPageTemplate

export const pageQuery = graphql`
  query Concept($concept: String, $slug: String) {
    concept: mdx(slug: {eq: $slug}) {
        slug
        frontmatter {
          wikipedia
          shortDescription
          relatedConcepts
          text {
            id
            sections
          }
        }
    }
    misconceptions: allMdx(
      filter: { 
        frontmatter: { 
          concepts: { in: [$concept] }
        } 
      }
      sort: { fields: fields___name, order: ASC }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            name
            pl
          }
          frontmatter {
            shortDescription
          }
        }
      }
    }
    pls: allMdx(
      filter: { frontmatter: { type: {eq: "pl"}}}
    ) {
      edges {
        node {
          frontmatter {
            id
          }
        }
      }
    }
    textbooks: allMdx(
      filter: { fileAbsolutePath: { glob: "**/src/data/textbooks/*.md" } }
    ) {
      nodes {
        frontmatter {
          title
          home
          author
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
  }
`
