import React from 'react'
import { Label } from 'semantic-ui-react'
import { Link } from "gatsby"
const _ = require("lodash")

const ConceptLabel = ({concept}) => {
  return (
    <Label
      as={Link}
      to={`/concepts/${_.kebabCase(concept)}`}
      color="brown"
    >
      {_.startCase(concept)}
    </Label>
  )
}

export default ConceptLabel
